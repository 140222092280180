import React from "react"
import SEO from '../../components/seo'
import Layout from '../../containers/layout/layout'
import Section from '../../containers/services/data-engineering'

export default () => (
    <Layout headerLayout={2}>
        {/* TODO: Data-engneering descriptions */}
        <SEO title="Data Engineering Teams for Enterprise, Government and Business." description="Atlasopen Data Engineering teams can help your organisation extract data from disparate sources into one single Data Lake and from here develop visualisations and dashboards to help your augment your teams business intelligence across organisational units."/>
        {/*<Banner/>*/}
        {/*<About/>*/}
        <Section/>
        {/*<JoinOurTeam/>*/}
        {/*<Testimonial/>*/}
    </Layout>
)

