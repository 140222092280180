import React from 'react'
import Heading from '../../../components/shared/heading'
import { SectionTitleWrap } from "../../company/join-our-team/join-our-team.stc"
import { AboutSectionWrap, SectionInnerLeft, SectionInnerRight } from '../web-development/about.stc'
import PropTypes from "prop-types"
import { useStaticQuery, graphql, navigate } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'
import Button from "../../../components/shared/button";
import { ClientLogo } from "../../home-slider/client-logos/logo-clients.stc";
import Metro from "../../../data/teams/images/Metro2.jpeg";

//queries the serviceswebdata.json file
const Section = ({ HeadingStyle, SubHeadingStyle, Paragraph, HeadingStyleServies }) => {
    const servicesQueryData = useStaticQuery(graphql`
        query ServicesDataEngineeringQuery {
            servicesdataengineeringJson(jsonId: {eq: "data-engineering"}) {
                dataengineeringServices {
                    title
                    classId
                    link
                    description
                    tableHeader
                    tableData
#                    image {
#                        childImageSharp {
#                            fluid(maxWidth: 960, quality: 100) {
#                                ...GatsbyImageSharpFluid_withWebp
#                            }
#                        }
#                    }
                }
            }
        }
    `)
    // const serviceData = servicesQueryData.servicesdataengineeringJson;
    // const dataengineeringServices = serviceData.dataengineeringServices;
    // console.log(serviceData.dataengineeringServices)

    // const dataContent = dataengineeringServices.map((dataengineeringService, index) =>
    //     <div className="col-1 offset-1" key={index}>
    //         <a style={{color: "black"}} href={dataengineeringService.link}>{dataengineeringService.title}</a>
    //     </div>
    // );

    // const dataContent = dataengineeringServices.map((dataengineeringService, index) =>
    //     <div className="col-1 offset-1" key={index}>
    //         <a style={{color: "black"}} href={dataengineeringService.link}>{dataengineeringService.title}</a>
    //     </div>
    // );
    // const content = dataengineeringServices.map((dataengineeringService, index) =>
    //     <div className="row services" key={index} id={dataengineeringService.classId}>
    //         <div className="col-2 offset-1">
    //             <SectionInner  style={{paddingRight: "10vh"}}>
    //                 <SectionTitleWrap>
    //                     <Heading {...HeadingStyleServies}>{dataengineeringService.title}</Heading>
    //                     {dataengineeringService.description.map((textData, i) => <Text key={`story-text-${i}`} {...Paragraph}>{textData}</Text>)}
    //                 </SectionTitleWrap>
    //             </SectionInner>
    //         </div>
    //         <div className="col-2">
    //             <SectionInner>
    //                 {dataengineeringService.image ?
    //                     <Fade right>
    //                         <img src={dataengineeringService.image.childImageSharp.fluid.src} alt={dataengineeringService.title}/>
    //                     </Fade>
    //                     :
    //                     <Fade right>
    //                         <SectionTitleWrap>
    //                             {dataengineeringService.tableHeader && <Heading {...SubHeadingStyle}>{dataengineeringService.tableHeader}</Heading> }
    //                             <div className="row">
    //                                 {dataengineeringService.tableData.map((textData, i) =>
    //                                     <div className="col-1" key={`story-text-${i}`}>
    //                                         <Text {...Paragraph}>{textData}</Text>
    //                                     </div>)
    //                                 }
    //                             </div>
    //                         </SectionTitleWrap>
    //                     </Fade>
    //                 }

    //             </SectionInner>
    //         </div>
    //     </div>
    // );

    return (
        <AboutSectionWrap>
            <div className="row service-top-section">
                <SectionInnerLeft>
                    <SectionTitleWrap>
                        <Heading className="engineering-header-left" {...HeadingStyle}>Data Engineering</Heading>
                        <br></br>
                        <h2 style={{ color: 'white' }} >We unlock data insights with Metro Tunnel Melbourne</h2>
                        <br></br>
                        <br></br>
                        <div
                            className="row service-top-section"
                        >
                            <ClientLogo
                                style={{ marginLeft: -10 }}
                                src={Metro}
                                alt="Atlasopen"
                            />
                            <Button
                                onClick={() => { navigate("/metro-tunnel-melbourne") }}
                                layout={2}
                                style={{ height: "100%", margin: "auto" }}
                            >
                                See How
                                <i className="ti-arrow-right"></i>
                            </Button>
                        </div>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <StaticImage className="dataEngineering-image" src={"../../../../src/data/images/webdev.png"} alt="ai header"/>
                </SectionInnerRight>
            </div>

            <div className="row services">
                <SectionInnerLeft>
                    <SectionTitleWrap>
                        <Heading className="trusted-data-partner" {...HeadingStyleServies}>A Trusted Data Partner</Heading>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <SectionTitleWrap>
                        {/*<p style={{ fontSize: 20 }}>Making sense of your data is hard enough. We help you navigate the complexities of your data sources, data schemas, data pipelines to extract, transform and load them into a single pane of glass. From here we can do many things, from data sharing and permissions to real-time and predictive analytics.</p>*/}

                        <h3>Data lives in many forms and in many places. Learn how we can bring it together to unlock your insights</h3>
                        {/*<Text {...Paragraph}>*/}
                        {/*We are your trusted Data Partner.*/}
                        {/*</Text>*/}
                    </SectionTitleWrap>
                </SectionInnerRight>
            </div>

            <div className="row services">
                <SectionInnerLeft>
                    <SectionTitleWrap>
                        <Heading style={{ marginTop: 30 }} {...HeadingStyleServies}>Surface Insights and Make Predictions</Heading>
                        <br></br>
                        {/*<h3 style={{ width: "70%" }}>Making sense of your data is hard enough. It lives in disparate systems and it's stored in different formats.</h3>*/}
                        <p style={{ width: "70%", fontSize: 20 }}>We mobilise your data bringing it into a central location.</p>
                        <p style={{ width: "70%", fontSize: 20 }}> We develop visualisations helping you analyse and predict insights into data sources you didn't know could be surfaced</p>
                        <h5 style={{ width: "70%", fontSize: 20 }}> Your Data - Working for You.</h5>
                        <Button style={{ backgroundColor: 'green', color: 'white' }} onClick={() => { navigate("/contact") }} layout={2}>Get in Touch<i className="ti-arrow-right"></i></Button>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <SectionTitleWrap>
                        <StaticImage className="surface-insight-image" src={"../../../../src/data/images/PowerBI1.png"} alt="ai header" />
                    </SectionTitleWrap>
                </SectionInnerRight>
            </div>
            {/*<div className="row services">*/}
            {/*    <div className="col-4 offset-1">*/}
            {/*        <SectionInner>*/}
            {/*            <SectionTitleWrap>*/}
            {/*                <Heading {...SubHeadingStyle}>ML AND AI SERVICES</Heading>*/}
            {/*            </SectionTitleWrap>*/}
            {/*        </SectionInner>*/}
            {/*    </div>*/}
            {/*     {dataContent}*/}
            {/*</div>*/}

            {/* {content}*/}

            <div className="row services">
                <SectionInnerLeft>
                    <SectionTitleWrap>
                        <Heading className="report-real-time" {...HeadingStyleServies}>Report Real-time Analytics On Key Metrics</Heading>
                        <br></br>
                        {/*<h3 style={{ width: "70%" }}>Making sense of your data is hard enough. It lives in disparate systems and it's stored in different formats.</h3>*/}
                        <p style={{ width: "70%", fontSize: 20 }}>We work behind the scenes to automate data flow from disparate sources.</p>
                        <p style={{ width: "70%", fontSize: 20 }}>We make reporting and visualisations effortless.</p>
                        <h5 style={{ width: "70%", fontSize: 20 }}>Clean, Datasets - Ready For Use.</h5>
                        <Button onClick={() => { navigate("/contact") }} style={{ backgroundColor: 'blue', color: 'white' }} layout={2}>Get in Touch<i className="ti-arrow-right"></i></Button>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <SectionTitleWrap>
                        <StaticImage className="report-realtime-image" src={"../../../../src/data/images/DataAnalytics.png"} alt="ai header"/>
                    </SectionTitleWrap>
                </SectionInnerRight>
            </div>
        </AboutSectionWrap>
    )
}
Section.propTypes = {
    HeadingStyle: PropTypes.object,
    SubHeadingStyle: PropTypes.object,
    Paragraph: PropTypes.object,
    HeadingStyleServies: PropTypes.object,
    aiServiceLeft: PropTypes.object
}

Section.defaultProps = {
    HeadingStyle: {
        fontSize: '60px',
        texttransform: 'capitalize',
        lineHeight: '60px',
        color: '#e4e4e4',
        fontweight: 600,
        responsive: {
            medium: {
                fontSize: '34px',
                lineHeight: 'initial'
            },
            small: {
                fontSize: '35px',
                lineHeight: '35px'
            }
        }
    },
    SubHeadingStyle: {
        fontSize: '30px',
        lineHeight: '30px',
        color: 'primary',
        fontweight: 300,
        responsive: {
            medium: {
                fontSize: '24px',
                lineHeight: 'initial'
            },
            small: {
                fontSize: '25px',
                lineHeight: '35px'
            }
        }
    },
    Paragraph: {
        color: 'black',
        fontSize: '20px'
    },
    HeadingStyleServies: {
        fontSize: '50px',
        texttransform: 'capitalize',
        lineHeight: '50px',
        color: 'primary',
        fontweight: 600,
        responsive: {
            medium: {
                fontSize: '34px',
                lineHeight: 'initial'
            },
            small: {
                fontSize: '35px',
                lineHeight: '35px'
            }
        }
    }
}

export default Section

